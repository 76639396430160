// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-brochure-tsx": () => import("./../../../src/pages/brochure.tsx" /* webpackChunkName: "component---src-pages-brochure-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dashboards-and-reports-tsx": () => import("./../../../src/pages/dashboards-and-reports.tsx" /* webpackChunkName: "component---src-pages-dashboards-and-reports-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-analytics-platform-tsx": () => import("./../../../src/pages/people-analytics-platform.tsx" /* webpackChunkName: "component---src-pages-people-analytics-platform-tsx" */),
  "component---src-pages-people-analytics-tsx": () => import("./../../../src/pages/people-analytics.tsx" /* webpackChunkName: "component---src-pages-people-analytics-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-transformation-analytics-tsx": () => import("./../../../src/pages/transformation-analytics.tsx" /* webpackChunkName: "component---src-pages-transformation-analytics-tsx" */),
  "component---src-pages-why-qebal-people-analytics-tsx": () => import("./../../../src/pages/why-qebal-people-analytics.tsx" /* webpackChunkName: "component---src-pages-why-qebal-people-analytics-tsx" */),
  "component---src-pages-workforce-planning-tsx": () => import("./../../../src/pages/workforce-planning.tsx" /* webpackChunkName: "component---src-pages-workforce-planning-tsx" */)
}

